<script>
  import { crossfade, fade } from 'svelte/transition'
  import { createEventDispatcher } from 'svelte'

  export let post
  export let viewingImg
  export let postUrl

  const isWeibo = post.user.type === 'weibo'
  const dispatch = createEventDispatcher()
</script>

<div
  transition:fade
  class="comments-container"
  on:click={e => {
    if (e.target.tagName === 'DIV') dispatch('hideComments', false)
  }}
>
  {#if post.comments}
    {#each post.comments as comment}
      <div class="comment">
        <a
          href={(isWeibo
            ? 'https://m.weibo.cn/u/'
            : 'https://m.okjike.com/users/') + comment.user.username}
          target="_blank"
        >
          <img
            class="avatar"
            src={comment.user.avatarImage.thumbnailUrl}
            alt=""
          />
        </a>
        <div>
          <a
            class="id"
            href={(isWeibo
              ? 'https://m.weibo.cn/u/'
              : 'https://m.okjike.com/users/') + comment.user.username}
            target="_blank">{comment.user.screenName}</a
          >
          <div>
            {#if isWeibo}
              <div>
                {@html comment.content}
              </div>
            {:else}
              <div>{comment.content}</div>
            {/if}
            {#if comment.pictures && comment.pictures.length > 0}
              <img
                class="rpl-img"
                in:crossfade
                class:gif={comment.pictures[0].picUrl.substr(
                  comment.pictures[0].picUrl.length - 4
                ) === '.gif'}
                src={comment.pictures[0].thumbnailUrl}
                alt=""
                on:load={evt => {
                  dispatch('bigImgLoaded', true)
                }}
                on:click={evt => {
                  comment.pictures[0].viewingUrl =
                    comment.pictures[0].thumbnailUrl
                  dispatch('setViewingImg', comment.pictures[0])
                  evt.preventDefault()
                  evt.target.src =
                    comment.pictures[0].picUrl.substr(
                      comment.pictures[0].picUrl.length - 4
                    ) === '.gif'
                      ? comment.pictures[0].picUrl
                      : comment.pictures[0].middlePicUrl
                  evt.target.onload = () =>
                    viewingImg && (viewingImg.viewingUrl = evt.target.src)
                }}
              />
            {/if}
            <div class="rpl-container">
              {#each comment.hotReplies as rpl}
                <div>
                  <div class="user-id">{rpl.user.screenName + ': '}</div>
                  {#if isWeibo}
                    <div>
                      {@html rpl.content}
                    </div>
                  {:else}
                    <div>{rpl.content}</div>
                  {/if}
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    {/each}
  {/if}

  {#if post.comments && post.commentCount > post.comments.length}
    <a target="_blank" href={postUrl}
      ><div class="comments-head">查看更多评论</div></a
    >
  {:else}
    <a
      class:center={post.comments && post.comments.length == 0}
      target="_blank"
      href={postUrl}><div class="comments-head">去评论</div></a
    >
  {/if}
</div>

<style lang="scss">
  .avatar {
    margin: 0.5rem 0.5rem 0 0;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  .comments-container {
    width: 100vw;
    top: -70px;
    background: #fafafae8;
    height: calc(100vh + 80px);
    // display: flex;
    position: fixed;
    // flex-direction: column;
    // justify-content: flex-start;
    z-index: 20000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    a {
      height: min-content;
    }
  }
  .comments-head {
    display: inline-block;
    margin: 2rem 0rem 20vh 2rem;
    font-size: 0.95rem;
    width: fit-content;
    color: var(--link-color) !important;
  }
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .comments-head {
      margin: 0;
      width: max-content;
    }
  }
  .comment {
    line-break: anywhere;
    display: flex;
    margin: 0.25rem 0.8rem;
    font-size: 0.9rem;
    &:first-child {
      margin-top: 30vh;
    }
    a {
      color: #666;
    }
    .id {
      opacity: 0.6;
      & + div {
        margin: 0.3rem 0 1rem;
      }
    }
    .rpl-img {
      width: 50%;
      border-radius: 5px;
      margin: 0.3rem 0;
    }
    :global(.rpl-container a) {
      pointer-events: none;
      filter: opacity(0.8);
    }
    .rpl-container {
      border: 3px #7ca3d4 solid;
      border-width: 0 0 0 5px;
      margin: 0.6rem 0 0.2rem;
      & > div {
        margin: 0.4rem;
      }
      .user-id {
        // margin: 0 3px 0 5px;
        filter: opacity(0.6);
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    .comments-container {
      background: #010101e1;
    }
    .comment a {
      color: #ececec;
    }
  }
</style>
