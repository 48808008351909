import { CF_KV_URL, PARSE_RSS } from './constants'

export default {
  makeRssSource(rssData, rssUrl) {
    const screenName = rssData.title ?? rssData.link

    return {
      screenName,
      username: rssUrl, // in fact its userID in jike API
      rssTitle: screenName,
      img: 'rss-avatar.png',
      id: rssUrl,
      updatedAt: rssData.lastBuildDate ?? null,
      type: 'rss',
    }
  },

  makePosts(rssData) {
    const items = rssData.item
    const rssSource = this.makeRssSource(rssData)

    return items.map(item => {
      return {
        createdAt: item.pubDate ?? null,
        id: item.link ?? item.guid,
        type: 'RSS_ITEM',
        title: item.title,
        content: item.description,
        user: rssSource,
        commentCount: 0,
        myParams: {}, // need this empty obj
      }
    })
  },
}
