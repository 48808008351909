<script>
  export let name = ''
  export let fill = 'none'
  export let stroke = 'currentColor'
  export let flex = false
  export let style = ''
  export let className = '5'
  export let onClick = () => {}
</script>

<!--
 //// Options ////////////
  cog
  switch
  people
   trash
  setting
  eye
  plus
  plus-circle
  save
  check
  check-circle
  ring
  cross
  dragud
  draglr
  people
  play
  help
  ////////////  ////
 -->

<div class:flex {style} class={className + ' icon'} on:click={onClick}>
  {#if name === 'setting'}
    <svg viewBox="0 0 20 20" {fill} {stroke} class="w-8 h-8">
      <path
        d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0
        000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102
        0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1
        0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
      />
    </svg>
  {:else if name === 'change'}
    <svg viewBox="0 0 20 20" {fill} {stroke} class="w-8 h-8">
      <path
        fill-rule="evenodd"
        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707
        7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414
        0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1
        1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  {:else if name === 'check'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5 13l4 4L19 7"
      />
    </svg>
  {:else if name === 'check-circle'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  {:else if name === 'right'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
  {:else if name === 'left'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 19l-7-7 7-7"
      />
    </svg>
  {:else if name === 'dotc-circle'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  {:else if name === 'switch'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0
        0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />
    </svg>
  {:else if name === 'cog'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573
        1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426
        1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37
        2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724
        1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0
        00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0
        001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07
        2.572-1.065z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  {:else if name === 'save'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
      />
    </svg>
  {:else if name === 'plus'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 4v16m8-8H4"
      />
    </svg>
  {:else if name === 'draglr'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
      />
    </svg>
  {:else if name === 'dragud'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
      />
    </svg>
  {:else if name === 'trash'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5
        4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
  {:else if name === 'ring'}
    <svg
      viewBox="0 0 24 24"
      {fill}
      {stroke}
      stroke-width="3px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  {:else if name === 'cross'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  {:else if name === 'eye'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274
        4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </svg>
  {:else if name === 'plus-circle'}
    <svg {fill} viewBox="0 0 24 24" {stroke} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  {:else if name === 'people'}
    <svg {fill} {stroke} viewBox="0 0 20 20">
      <path
        d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93
        17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119
        16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
      />
    </svg>
  {:else if name === 'play'}
    <svg {fill} {stroke} viewBox="0 0 20 20">
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0
        001.555.832l3-2a1 1 0 000-1.664l-3-2z"
        clip-rule="evenodd"
      />
    </svg>
  {:else if name === 'refresh'}
    <svg
      fill="none"
      {stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
    >
      <path
        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0
        0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />
    </svg>
  {:else if name === 'arrow-up'}
    <svg {fill} {stroke} viewBox="0 0 20 20">
      <path
        fill-rule="evenodd"
        d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414
        1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>
  {:else if name === 'help'}
    <svg {fill} {stroke} viewBox="0 0 20 20">
      <path
        fill-rule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0
        11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0
        011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
        clip-rule="evenodd"
      />
    </svg>
  {:else if name === 'thumb'}
    <svg
      class="w-6 h-6"
      {fill}
      {stroke}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      ><path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
      /></svg
    >
  {/if}
</div>

<style lang="scss">
  .icon {
    pointer-events: none;
    width: 32px;
    height: 32px;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
  .flex {
    width: 100%;
    height: 100%;
  }
</style>
