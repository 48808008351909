<script>
  import { onMount } from 'svelte'
  import PostItem from './postItem.svelte'
  import ICON from './myicons.svelte'
  import { syncRssLinks, syncData, copy, debounce } from './helpers.svelte'
  import { get, set } from 'idb-keyval'
  import Toast, { toast } from './Toast.svelte'
  import { CORS_PREFIX, PARSE_RSS } from './constants'
  import rss from './rss'
  // import PhotoSwipe from '../PhotoSwipe.svelte'

  const DEMO_IDS =
    'wenhao1996;wangxingbot;6293E336-9D9B-4DE0-AF05-0CC73F765570;E2331DA5-73A4-4FE0-8359-99AA08951943;0176da14-fea3-443a-a3de-71b86d37e989'
  const hasClipboardAPI = navigator.clipboard && navigator.clipboard.readText
  let delTimer, autoFetchTimer
  let rssList = []
  let jikeIDs = [],
    allIDs = [],
    weiboIDMap = {},
    posts = [],
    postDB = {},
    lastDB = {},
    autoFetchInterval = 1000 * 60 * 3, // every 3 minutes
    peoples = undefined,
    showHelp = false,
    iOSPasting = false,
    hasNewPost = false,
    viewingLimit = 50
  let state = ''
  let btnTimer,
    showRefresh = true

  onMount(() => {
    if (window.location.hash === '#force') {
      forceAddID('9A4E7609-BB75-49C8-A79B-39551ED698C1$$1134424202')
    }
    if (!String.prototype.splice) {
      String.prototype.splice = function (start, delCount, newSubStr) {
        return (
          this.slice(0, start) +
          (newSubStr ? newSubStr : '') +
          this.slice(start + Math.abs(delCount))
        )
      }
    }
    if (window.indexedDB) {
      loadLocalData().then(() => {
        updateAllIDs()
        fetchData()
      })
    } else {
      loadLocalData()
      updateAllIDs()
      fetchData()
    }

    /**
     * Setup auto fetch timer
     */
    autoFetchTimer = setInterval(() => {
      if (allIDs.length == 0) return

      let gotJike, gotWeibo, gotRss
      state = 'auto-fetching'
      console.log('start auto fetching')
      Promise.all(
        jikeIDs.map(id => fetch('https://m.okjike.com/api/users/' + id))
      )
        .then(responses =>
          Promise.all(
            responses.map(rsp => {
              if (rsp.ok) {
                return rsp.json() //async
              } else {
                delID(rsp.url.match(/api\/users\/(.*)/)[1])
                console.log('deleted missing user')
                return false
              }
            })
          )
        )
        .then(data => {
          if (
            data
              .map(
                d => d && addJikeUserPosts2DB(d) // could be false or json
              )
              .filter(rst => rst)
              .includes(true)
          )
            hasNewPost = true
          console.log('got jike response')
          gotJike = true
          if (gotJike && gotWeibo && gotRss) {
            state = ''
            store('peoples', JSON.stringify(peoples))
            savePostDB()
          }
        })

      Promise.all(
        Object.keys(weiboIDMap).map(id => fetchWeiboPostsFromUser(id))
      ).then(rsps => {
        console.log('got weibo rsps', rsps)
        if (rsps.includes(true)) hasNewPost
        gotWeibo = true
        if (gotJike && gotWeibo && gotRss) {
          state = ''
          store('peoples', JSON.stringify(peoples))
          savePostDB()
        }
      })

      Promise.all(rssList.map(url => fetchRssJsonData(url).catch(e => e))).then(
        () => {
          gotRss = true
          if (gotJike && gotWeibo && gotRss) {
            state = ''
            store('peoples', JSON.stringify(peoples))
            savePostDB()
          }
        }
      )
    }, autoFetchInterval)

    return () => {
      console.log('clear auto fetch')
      clearInterval(autoFetchTimer)
    }
  })

  async function fetchRssJsonData(rssUrl) {
    console.log('inside: fetchRssData', rssUrl)
    const res = await fetch(PARSE_RSS + rssUrl)
    if (res.ok) {
      const rssData = (await res.json()).data
      // console.log('rssData', rssData)
      const rssPosts = rss.makePosts(rssData)
      rssPosts.forEach(post => {
        if (!postDB.hasOwnProperty(post.id)) {
          postDB[post.id] = post
        }
      })
      addPeople(rss.makeRssSource(rssData, rssUrl))

      return rssData
    }
  }

  async function addNewRssSource(rssUrl) {
    const res = await fetch(PARSE_RSS + rssUrl)
    if (res.ok) {
      const rssData = (await res.json()).data
      // console.log('rssData', rssData)
      const rssPosts = rss.makePosts(rssData)
      rssPosts.forEach(post => {
        if (!postDB.hasOwnProperty(post.id)) {
          postDB[post.id] = post
        }
      })
      addPeople(rss.makeRssSource(rssData, rssUrl))

      rssList.push(rssUrl)
      rssList = Array.from(new Set(rssList))
      posts = genSortedPosts(postDB, viewingLimit)
      localStorage.setItem('rss-list', rssList.join(';'))
    }
  }

  function forceAddID(id) {
    jikeIDs.push(id)
    store('ids', jikeIDs.join(';'))
    // console.log('forcely added id', id)
  }

  function genSortedPosts(db, limit) {
    return Object.values(db)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, limit)
  }
  function addPeople(userData) {
    // console.log('a-ddPeople', userData)
    if (!peoples) peoples = {}
    peoples[userData.username] = {
      img: userData.img,
      id: userData.id,
      rssTitle: userData.rssTitle,
      updatedAt: userData.updatedAt,
      type: userData.type,
    }
    //console.log(peoples)
  }
  function removePeople(id) {
    delete peoples[id]
    store('peoples', JSON.stringify(peoples))
  }
  function getPostByID(id) {
    return postDB[id]
  }

  // TODO  remove unnecessary saves
  function savePostDB() {
    // postDB = allIDs.map((id) => postDB[id])
    console.log('store postDB', postDB)
    // 只保存最近2000条
    const db = genSortedPosts(postDB, 2000).reduce((acc, p) => {
      acc[p.id] = p
      return acc
    }, {})
    store('postDB', JSON.stringify(db))
  }
  function addJikeUserPosts2DB(obj) {
    let hasNew = false
    obj.posts.forEach(p => {
      if (!(p.id in postDB)) {
        hasNew = true
      }
      p.createdAt = p.actionTime
      postDB[p.id] = { ...postDB[p.id], ...p }
    })
    if (obj.posts[0]) {
      obj.posts.sort((a, z) => new Date(z.actionTime) - new Date(a.actionTime))
      addPeople({
        screenName: obj.user.screenName,
        username: obj.user.username,
        img: obj.user.avatarImage.thumbnailUrl,
        id: obj.user.screenName,
        updatedAt: obj.posts[0].actionTime,
        type: 'jike',
      })
    }
    return hasNew
  }
  function updatePostsInUI() {
    lastDB = postDB
    const pIDs = Object.keys(peoples)
    posts = genSortedPosts(postDB, viewingLimit)
    allIDs = [...jikeIDs, ...rssList]
      .concat(Object.keys(weiboIDMap))
      .filter(id => pIDs.includes(id))

    allIDs.sort(
      (a, b) => new Date(peoples[b].updatedAt) - new Date(peoples[a].updatedAt)
    )
    console.log('updated posts in ui', viewingLimit)
  }
  function fetchData() {
    state = 'loading'
    // const tmpposts = []
    let gotJike, gotWeibo, gotRss
    Promise.all(
      jikeIDs.map(id => fetch('https://m.okjike.com/api/users/' + id))
    )
      .then(responses =>
        Promise.all(
          responses.map(rsp => {
            if (rsp.ok) {
              return rsp.json() // async
            } else {
              delID(rsp.url.match(/api\/users\/(.*)/)[1])
              console.log('deleted missing user')
              return false
            }
          })
        )
      )
      .then(data => {
        gotJike = true
        console.log('got jike response')
        if (gotJike && gotWeibo) {
          state = ''
          store('peoples', JSON.stringify(peoples))
          updateAllIDs()
        }
        data
          .map(d => d && addJikeUserPosts2DB(d))
          .filter(rst => rst)
          .includes(true) && updatePostsInUI()
      })
    Promise.all(
      Object.keys(weiboIDMap).map(id => fetchWeiboPostsFromUser(id))
    ).then(fetchResults => {
      //  bool[]
      // console.log('got weibo rsps', fetchResults)
      gotWeibo = true
      if (gotJike && gotWeibo) {
        state = ''
        updatePostsInUI()
        updateAllIDs()
        store('peoples', JSON.stringify(peoples))
        savePostDB()
      }
    })

    // rss data
    Promise.all(rssList.map(url => fetchRssJsonData(url).catch(e => e))).then(
      () => {
        gotRss = true
        if (gotJike && gotWeibo && gotRss) {
          state = ''
          store('peoples', JSON.stringify(peoples))
          savePostDB()
        }
      }
    )
  }

  function store(k, v) {
    if (window.indexedDB) {
      set(k, v)
    } else {
      localStorage.setItem(k, v)
    }
  }
  function read(k) {
    if (window.indexedDB) {
      return get(k)
    } else {
      return localStorage.getItem(k)
    }
  }
  function delID(id) {
    const isRss = rssList.includes(id)
    const type = isRss
      ? 'rss'
      : weiboIDMap.hasOwnProperty(id)
      ? 'weibo'
      : 'jike'
    if (type === 'jike') {
      jikeIDs.splice(jikeIDs.indexOf(id), 1)
      jikeIDs = [...jikeIDs]
      // localStorage.setItem('ids', IDs.join(';'))
      // set('ids', IDs.join(';'))
      store('ids', jikeIDs.join(';'))
    } else if (type === 'weibo') {
      delete weiboIDMap[id]
      store('weiboIDs', Object.keys(weiboIDMap).join(';'))
    } else if (type === 'rss') {
      rssList.splice(rssList.indexOf(id), 1)
      rssList = [...rssList]
      localStorage.setItem('rss-list', rssList.join(';'))
    }
    removePeople(id)
    updateAllIDs()
    console.log('delID-', type, id)
  }
  async function loadLocalData() {
    let idTxt, wbIDTxt
    // load rss urls
    const rssTxt = localStorage.getItem('rss-list')
    rssList = rssTxt ? rssTxt.split(';') : []
    // ---- rss------
    if (!window.indexedDB) {
      idTxt = localStorage.getItem('ids')
      wbIDTxt = localStorage.getItem('weiboIDs')
      if (idTxt) {
        jikeIDs = idTxt.split(';')
        weiboIDMap = wbIDTxt.split(';').reduce((acc, cur) => {
          acc[cur] = {}
          return acc
        }, {})
        // console.log('loadedidb jikeIDs', jikeIDs)
      }
      peoples = JSON.parse(localStorage.getItem('peoples'))
      postDB = JSON.parse(localStorage.getItem('postDB'))
      // console.log('loadedidb peoples postDB', peoples, postDB)

      if (window.location.hash == '#demo') importIDs(DEMO_IDS)
    } else {
      await get('peoples').then(val => {
        peoples = JSON.parse(val ? val : localStorage.getItem('peoples'))

        console.log('loadedidb peoples', peoples)
        updateAllIDs()
      })
      await get('ids').then(val => {
        if (val) {
          idTxt = val
          // console.log('loadidb ids', idTxt)
        } else {
          idTxt = localStorage.getItem('ids')
          // console.log('loadlocalStore ids', idTxt)
        }
        if (idTxt) {
          jikeIDs = idTxt.split(';')
        }

        if (window.location.hash == '#demo') importIDs(DEMO_IDS)
      })
      await get('weiboIDs').then(v => {
        if (v) {
          wbIDTxt = v
          // console.log('wbtxt', wbIDTxt)
        } else {
          wbIDTxt = localStorage.getItem('weiboIDs')
        }
        if (wbIDTxt) {
          weiboIDMap = wbIDTxt.split(';').reduce((acc, cur) => {
            acc[cur] = {}
            return acc
          }, {})
          // console.log('do', weiboIDMap)
        }
      })

      return get('postDB').then(val => {
        postDB = JSON.parse(val ? val : localStorage.getItem('postDB')) || {}
        posts = genSortedPosts(postDB, viewingLimit)
        console.log('loadedidb posts', posts)
        updateAllIDs()
      })
    }
  }
  function importRssLinks(links) {
    if (!links) return
    // console.log('importRssLinks', links)
    Promise.all(links.filter(l => l).map(rssUrl => addID(rssUrl, 'rss'))).then(
      () => {
        updateAllIDs()
        updatePostsInUI()
        showHelp = false
      }
    )
  }

  function importIDs(strr) {
    const [jkstr, wbstr] = strr.split('$$')
    const jkIDs = jkstr && jkstr.split(';'),
      wbIDs = wbstr && wbstr.split(';')
    jkIDs &&
      Promise.all(jkIDs.map(id => addID(id, 'jike'))).then(() => {
        updateAllIDs()
        updatePostsInUI()
        showHelp = false
      })
    wbIDs &&
      Promise.all(wbIDs.map(id => addID(id, 'weibo'))).then(() => {
        updateAllIDs()
        updatePostsInUI()
        showHelp = false
      })
  }

  function prepareSyncData() {
    // const weiboIDs = Object.keys(weiboIDMap).join(';')
    const jikeIDMap = jikeIDs.reduce((pre, cur) => ({ ...pre, [cur]: 1 }), {})
    // return { ids: jikeIDs.join(';') + '$$' + weiboIDs }
    return { weiboIDMap, jikeIDMap }
  }

  function syncIDs() {
    const uid = localStorage.getItem('jike-uid')
    if (uid) {
      state = '正在同步关注列表'
      loadLocalData().then(() => {
        const data = prepareSyncData()
        syncData(uid, data)
          .then(data => {
            const weiboStr = Object.keys(data.weiboIDMap).join(';')
            const jikeStr = Object.keys(data.jikeIDMap).join(';')
            importIDs(jikeStr + '$$' + weiboStr)
          })
          .then(() => {
            return syncRssLinks(uid, rssList).then(list => {
              // console.log('import rss links', list)
              importRssLinks(list)
            })
          })
          .finally(() => {
            state = ''
          })
      })
    } else {
      state = '请输入id用于同步数据，建议使用手机号或者邮箱'
      setTimeout(() => {
        state = ''
      }, 3000)
    }
  }

  function updateAllIDs() {
    if (!peoples) peoples = {}
    const pIDs = Object.keys(peoples)
    // console.log({ pIDs, rssList })
    allIDs = [...jikeIDs, ...rssList]
      .concat(Object.keys(weiboIDMap))
      .filter(id => pIDs.includes(id))
    // console.log('allid', allIDs, 'people')
    // console.log('aa', allIDs, jikeIDs, weiboIDMap)
    allIDs.sort(
      (a, b) => new Date(peoples[b].updatedAt) - new Date(peoples[a].updatedAt)
    )
    // console.log('allid', allIDs)
  }
  /**
   * type: jike | weibo
   */
  async function addID(id, type = 'jike', showToast = false) {
    if (
      jikeIDs.indexOf(id) >= 0 ||
      weiboIDMap.hasOwnProperty(id) ||
      rssList.indexOf(id) >= 0
    ) {
      console.log('id exists already')
      return
    }
    if (type === 'jike') {
      await addJikeID(id)
    } else if (type === 'weibo') {
      await addWeiboID(id)
    } else if (type === 'rss') {
      await addNewRssSource(id)
    }
  }

  function addJikeID(id) {
    return fetch('https://m.okjike.com/api/users/' + id)
      .then(rsp => {
        if (rsp.ok) {
          return rsp.json()
        } else {
          return false
        }
      })
      .then(
        d => {
          if (!d) return
          jikeIDs.push(id)
          d.posts.forEach(p => {
            if (!postDB.hasOwnProperty(p.id)) postDB[p.id] = p
          })

          if (d.posts[0]) {
            d.posts.sort(
              (a, z) => new Date(z.actionTime) - new Date(a.actionTime)
            )
            addPeople({
              screenName: d.user.screenName,
              username: d.user.username,
              img: d.user.avatarImage.thumbnailUrl,
              id: d.user.screenName,
              updatedAt: d.posts[0].actionTime,
              type: 'jike',
            })
          }

          posts = genSortedPosts(postDB, viewingLimit)
          store('ids', jikeIDs.join(';'))
          // console.log('add jike', id)
        },
        err => console.log(err)
      )
  }
  async function addWeiboID(id) {
    await fetchWeiboPostsFromUser(id)
    posts = genSortedPosts(postDB, viewingLimit)
    store('weiboIDs', Object.keys(weiboIDMap).join(';'))
  }
  async function fetchWeiboPostsFromUser(id) {
    try {
      const d = await (
        await fetch(CORS_PREFIX + 'https://m.weibo.cn/profile/info?uid=' + id, {
          headers: {
            'content-type': 'application/json',
          },
        })
      ).json()
      if (d.ok != 1) {
        console.log('failed to fetch weibo user info')
        return
      }

      const orderedUserPosts = d.data.statuses.sort(
        (a, z) => new Date(z.created_at) - new Date(a.created_at)
      )
      const userData = {
        id: d.data.user.screen_name,
        screenName: d.data.user.screen_name,
        username: id,
        img: d.data.user.profile_image_url,
        updatedAt:
          orderedUserPosts && orderedUserPosts[0]
            ? orderedUserPosts[0].created_at
            : 100000000,
        type: 'weibo',
      }
      addPeople(userData)
      const wbPosts = d.data.statuses.map(p => {
        const tmp = {
          createdAt: p.created_at,
          id: p.id,
          type: p.retweeted_status ? 'REPOST' : 'ORIGIN',
          content: p.text,
          user: userData,
          commentCount: p.comments_count,
          retweeted_status: p.retweeted_status,
          myParams: { isLongText: p.isLongText },
        }
        if (p.pic_num > 0) {
          tmp.pictures = p.pics.map(pic => {
            return {
              thumbnailUrl: pic.url,
              middlePicUrl: pic.large.url,
              picUrl: pic.large.url,
              geo: pic.geo,
              largeGeo: pic.large.geo,
            }
          })
        }
        return tmp
      })
      weiboIDMap[id] = userData
      let tmphasNew = false
      wbPosts.forEach(p => {
        if (!postDB.hasOwnProperty(p.id)) {
          tmphasNew = true
        }
        if (postDB.hasOwnProperty(p.id) && postDB[p.id].myParams.useOldText) {
          postDB[p.id] = { ...p, ...postDB[p.id] }
        } else {
          postDB[p.id] = { ...postDB[p.id], ...p }
        }
      })
      return tmphasNew
    } catch (error) {
      console.log('failed to fetch weibo user info')
      return
    }
  }
  async function onBtnAdd(evt) {
    //
    if (hasClipboardAPI) {
      const link = await navigator.clipboard.readText()
      onPasteLink(link)
    } else {
      // click input
    }
  }
  function onPasteLink(link) {
    const wbReg = /.*weibo.*?[\/|=](?<uid>\d{10})\/?/
    const mt = link.match(wbReg)
    // weibo link
    if (mt && mt.groups && mt.groups.uid) {
      toast('正在添加微博用户')
      addID(mt.groups.uid, 'weibo').then(() => {
        state = ''
        updateAllIDs()
        updatePostsInUI()
        toast('添加完成')
      })
      return
    }
    const isJikeLink = link.indexOf('okjike.com') > -1
    if (!isJikeLink) {
      // it's rss then
      toast('正在添加RSS源')
      addID(link, 'rss').then(() => {
        state = ''
        updateAllIDs()
        updatePostsInUI()
        toast('添加完成')
      })
      return
    }

    const webLink = link.indexOf('https://web')
    if (webLink >= 0) {
      link = link.splice(8, 3, 'm')
    }
    const start = link.indexOf('.okjike.com/')
    if (start >= 0) {
      state = 'loading'
      link = link.splice(start + 12, 0, 'api/')
      // console.log('new link', link, aa)
      toast('正在添加即刻用户')
      fetch(link)
        .then(rsp => rsp.json())
        .then(d => {
          addID(d.user.username).then(() => {
            state = ''
            updateAllIDs()
            updatePostsInUI()
            toast('添加完成')
          })
        })
    } else {
      state = '链接无效'
      setTimeout(() => {
        state = ''
      }, 1000)
    }
  }
</script>

<svelte:head>
  <title>即刻Lite(非官方)</title>
</svelte:head>
<svelte:window
  on:scroll={evt => {
    if (window.pageYOffset <= 100) {
      showRefresh = true
      hasNewPost && updatePostsInUI()
      hasNewPost = false
    } else {
      showRefresh = false
    }
    if (document.body.scrollHeight - window.pageYOffset <= 2500 && !showHelp) {
      if (viewingLimit >= Object.keys(postDB).length) return
      viewingLimit += 100
      console.log('new limit', viewingLimit)
      posts = Object.values(lastDB)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, viewingLimit)
      console.log('new posts', posts)
    }
  }}
/>
<div class="time-bg-1" />
<div
  class="status-light"
  class:loading={state == 'loading' || state == 'auto-fetching'}
  class:has-new={hasNewPost}
/>
<!-- <div class="time-bg-2">
</div> -->
{#if allIDs.length == 0 || showHelp}
  <div class="guide">
    <i class="notice"
      >*非官方项目<br /><span>已支持PWA，把网页保存到桌面即可变成APP</span></i
    >
    {#if hasClipboardAPI}
      <ol>
        <li>复制即友动态链接（也支持微博链接、RSS地址）</li>
        <li>点+号开始关注该即友</li>
      </ol>
      <img src="jike-help.jpg" alt="" />
      <button class="btn-add" on:click={onBtnAdd}>
        <ICON name="plus-circle" flex={true} />
      </button>
    {:else}
      <p>暂不支持该浏览器，建议使用Chrome或者Safari。</p>
    {/if}
    <span class="state">{state == 'auto-fetching' ? '' : state}</span>
    {#if showHelp}
      <button
        class="btn-add"
        on:click={() => {
          showHelp = false
        }}
      >
        知道了
      </button>
    {/if}

    <br />
    <div class="adv-settings">
      <button
        on:click={() => {
          const uid = localStorage.getItem('jike-uid')
          const newUID = window.prompt(
            '输入id进行跨设备数据同步，建议使用邮箱/手机号码。',
            uid ?? ''
          )
          if (!newUID) {
            return
          }
          localStorage.setItem('jike-uid', newUID)
          syncIDs()
        }}
      >
        设置 ID
      </button>
      <button on:click={syncIDs}>同步关注列表</button>
    </div>
  </div>
  <hr />
  <div class="warning-notice">下面很危险, 点一下就清空了</div>
  <button
    on:click={() => {
      jikeIDs.length = 0
      // store('rss-list', '')
      localStorage.setItem('rss-list', null)
      store('ids', '')
      store('peoples', '')
      store('weiboIDs', '')
    }}>清空关注列表</button
  >
  <button
    on:click={() => {
      jikeIDs.length = 0
      store('postDB', '')
    }}>清空所有动态</button
  >
{:else}
  <!-- main page -->
  <main>
    <Toast />

    <div class="heading">
      <h2>
        <span>即刻Lite</span>
        <span
          on:click={() => {
            showHelp = true
          }}
        >
          <ICON
            stroke="none"
            fill="currentColor"
            flex={true}
            name="help"
            style="display:flex;width:24px;height:24px;"
          />
        </span>
      </h2>
      {#if hasClipboardAPI}
        <button class="btn-add" on:click={onBtnAdd}>
          <span class={'state ' + state}
            >{state == 'auto-fetching' ? '' : state}</span
          >
          <ICON name="plus-circle" stroke="currentColor" />
        </button>
      {/if}
    </div>
    {#if peoples != undefined}
      <div class="peoples">
        {#each allIDs as id}
          <figure
            data-id={id}
            on:mousedown={evt => {
              state = 'deleting'
              delTimer = setTimeout(() => {
                const deltId = peoples[id].id
                delID(evt.target.getAttribute('data-id'))
                state = 'delt'
                toast(`已删除「${deltId}」`)
              }, 3000)
            }}
            on:mouseup={() => {
              state = ''
              clearTimeout(delTimer)
            }}
            on:touchstart={evt => {
              state = 'deleting'
              delTimer = setTimeout(() => {
                const deltId = peoples[id].id

                delID(evt.target.getAttribute('data-id'))
                state = 'delt'
                toast(`已删除「${deltId}」`)
              }, 3000)
            }}
            on:mouseout={() => {
              state = ''
              clearTimeout(delTimer)
            }}
            on:touchend={() => {
              state = ''
              clearTimeout(delTimer)
            }}
          >
            <img src={peoples[id] && peoples[id].img} alt="" />
            <figcaption>
              {peoples[id] && peoples[id].rssTitle
                ? peoples[id].rssTitle
                : peoples[id].id}
            </figcaption>
          </figure>
        {/each}
      </div>
    {/if}

    <h2
      class="h2-feeds"
      on:click={() => {
        fetchData()
      }}
    >
      动态
      <span class={'icon-refresh ' + state}>
        <ICON name="refresh" style="display:flex;width:22px;height:22px;" />
      </span>
    </h2>
    {#each posts as post (post.id)}
      <PostItem {post} {getPostByID} {savePostDB} {postDB} />
    {/each}
    <button
      class="btn-top"
      class:blue-dot={hasNewPost}
      on:click={e => {
        e.preventDefault()
        if (showRefresh) {
          fetchData()
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          showRefresh = true
          hasNewPost && updatePostsInUI()
          hasNewPost = false
        }
      }}
    >
      {#if showRefresh}
        <ICON
          className=""
          name="refresh"
          fill="currentColor"
          stroke="currentColor"
        />
      {:else}
        <ICON name="arrow-up" fill="currentColor" stroke="currentColor" />
      {/if}
    </button>
  </main>
  <!-- <PhotoSwipe /> -->
{/if}

<style lang="scss">
  :global(body::-webkit-scrollbar) {
    display: none;
  }
  .top-mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #3338;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }
  main {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    overflow: scroll hidden;
    position: relative;
  }
  input {
    display: none;
  }
  h2 {
    position: sticky;
    top: 10px;
  }
  .btn-add {
    border: none;
    background: none;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    margin: 0 0.8rem 1.3rem;
    h2 {
      margin: 0;
      display: flex;
      align-items: center;
      .icon {
        width: 16px;
        height: 16px;
      }
    }
    label,
    .btn-add {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: end;
      align-items: center;
      .state {
        margin-right: 1rem;
      }
    }
  }
  .peoples {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-x: scroll;
    figure {
      max-width: 64px;
      min-width: 64px;
      text-align: center;
      margin: 0 0.3rem;
      img {
        width: 48px;
        border-radius: 50%;
      }
      * {
        pointer-events: none;
      }
      figcaption {
        font-size: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .h2-feeds {
    margin-left: 0.8rem;
    display: flex;
    align-items: center;
    .icon-refresh {
      margin-left: 4px;
      &.loading {
        animation: spinning infinite 3s linear;
      }
    }
    @keyframes spinning {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
  .guide {
    background: #fafafa;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    .notice {
      position: absolute;
      top: 10px;
      left: 8px;
    }
    label,
    .btn-add {
      width: 96px;
      margin-top: 1rem;
      position: relative;
      color: #313233;
    }
    img {
      width: 60vw;
      filter: none !important;
    }
  }
  .state {
    text-transform: capitalize;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      width: 0;
      height: 2px;
      transition: width linear 0.1s;
    }
    &.delt {
      visibility: hidden;
      width: 0px;
    }
    &.deleting {
      &::after {
        transition-duration: 3s;
        background: red;
        width: 100%;
      }
    }
    &.loading {
      &::after {
        background: #0abb12;
        width: 100%;
        transition-duration: 6s;
      }
    }
  }
  .btn-top {
    cursor: pointer;
    width: 3.7rem;
    height: 3.7rem;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    right: 2rem;
    bottom: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #8f8f8fa4;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    &.blue-dot::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      top: 5px;
      right: 3px;
      background: #ffe411;
    }
  }

  .time-bg-1 {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(51, 51, 51, 0.757);
    position: fixed;
    left: 0;
    top: -80px;
    width: 100vw;
    height: 81px;
    z-index: 100;
  }
  .status-light {
    width: 3px;
    height: 3px;
    // border-radius: 1px;
    background: #6c5;
    position: fixed;
    top: -38px;
    right: 50px;
    z-index: 101;
    &.loading {
      background: #56c;
    }
    &.has-new {
      background: #ffe411 !important;
    }
  }
  .adv-settings {
    button {
      border: none;
      background: #ddd;
      border-radius: 33px;
      padding: 6px 14px;
      margin: 0 13px;
    }
  }

  hr {
    margin-top: 40vh;
  }
  .warning-notice {
    color: wheat;
    font-weight: 700;
  }

  @media (prefers-color-scheme: dark) {
    .guide,
    main {
      background: #313233;
      color: #eaeaea !important;
    }
    button,
    input {
      color: #eaeaea !important;
      background: #313233;
    }
    img {
      filter: brightness(0.86) !important;
    }
    .adv-settings {
      button {
        background: #595959;
      }
    }
  }
</style>
